import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine/carrier';

export const apiSearchCarrier = async params => {
  // 查询我的承运人列表
  return await http.post(`${publicPath}/findList`, params, false);
};

export const apiSearchCarrierByPhone = async params => {
  return await http.post(`${publicPath}/findByPhone`, params, false);
};

export const apiSearchCarrierByIdCard = async params => {
  return await http.post(`${publicPath}/findCarrierListByIdCard`, params, false);
};

export const apiJoinFleet = async params => {
  return await http.post(`${publicPath}/add/driver`, params, false);
};

export const addDriverToFleetApi = async params => {
  // 查询车队
  return await http.post(`${publicPath}/add/driver`, params, false);
};

export const removeDriverFromFleetApi = async params => {
  return await http.post(`${publicPath}/remove/driver`, params, false);
};

export const apiCarrierTruckAuth = async params => {
  // 车队长添加车辆
  return await http.post(`/api/driver-center/driver/carrier/truck/truckAuth`, params, false);
};

// export const apiUpdateCarrierTruckInfo = async params => {
//   return await http.post(`/api/driver-center/driver/carrier/truck/updateTruckInfo`, params, false);
// };

// 查询是否已是审核车辆
export const apiCheckCarrierCarNumber = async params => {
  return await http.post(`/api/driver-center/driver/carrier/truck/verifyCarLicensePlate`, params, false);
};
export const apiAddCarrierTruckByCarNumber = async params => {
  return await http.post(`/api/driver-center/driver/carrier/truck/addCarLicensePlate`, params, false);
};

export const apiCreateCarrier = async (params = {}) => {
  return await http.post(`/api/driver-center/driver/mine/carrier/createCarrier`, params, false);
};

export const apiRealNameCarrier = async params => {
  return await http.post(`/api/driver-center/driver/mine/carrier/realNameAuth`, params, false);
};

// 1.3.4 车队长修改认证通过的车辆
export const updateTruckInfoCaptainApi = async params => {
  return await http.post(`/api/driver-center/driver/carrier/truck/updateTruckInfo`, params, false);
};

// 1.3.4 车队长重新认证通过的车辆
export const afreshTruckInfoCaptainApi = async params => {
  return await http.post(`/api/driver-center/driver/carrier/truck/afreshAddTruckInfo`, params, false);
};
