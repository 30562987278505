import { Persistent } from '@/core/cache/persistent';

export function getDataCache(key) {
  const fn = Persistent.getLocal;
  return fn(key);
}

export function setDataCache(key, value) {
  const fn = Persistent.setLocal;
  return fn(key, value, true);
}

export function getDataSessionCache(key) {
  const fn = Persistent.getSession;
  return fn(key);
}

export function setDataSessionCache(key, value) {
  const fn = Persistent.setSession;
  return fn(key, value, true);
}
