import { http } from '../utils/http';
let path;

path = process.env.VUE_APP_PATH + '/resource';

// OCR身份证识别
export const ocrIdCardApi = async data => {
  return await http['post'](`${path}/thirdparty/ocr/idCard`, data, false);
};

path = process.env.VUE_APP_PATH + '/driver-center';

// 获取实名认证信息
export const getRealNameInfoApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/getRealNameInfo`, data, false);
};
// 申请实名认证
export const realNameAuthApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/realNameAuth`, data, false);
};
