<template>
  <div class="flex flex-col uploader-wrap tos-default-bg">
    <div class="flex-1 overflow-hidden tos-uploader-bg" :class="bgClassRef">
      <van-uploader
        ref="uploaderRef"
        :disabled="readonly || disabled"
        :deletable="!readonly && !disabled"
        v-model="currentValueRef"
        v-bind="$attrs"
        :after-read="afterRead"
      >
        <div class="uploader-button-wrap" v-show="!readonly && !disabled">
          <div class="justify-center uploader-button d-flex align-center">
            <div class="justify-center camera-button d-flex align-center">
              <Icon name="icon_upload" />
            </div>
          </div>

          <!-- <div v-if="isAndroidClient" class="click-mask" @click="onTakePhoto"></div> -->
        </div>
        <div
          v-if="isAndroidClient && !readonly && !disabled"
          class="absolute w-full h-full click-mask"
          @click="onTakePhoto"
        ></div>
      </van-uploader>
    </div>
    <div class="icon-delete" v-if="currentValueRef[0] && !readonly && !disabled" @click="onDelete">
      <Icon name="a-icon_deletepicture"></Icon>
    </div>
    <div v-if="$slots.default" class="relative pt-8 text-center text-note text-14"><slot></slot></div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { Uploader as VanUploader } from 'vant';
import { apiFileTemporaryUrl } from '@/api/common';
import { uploadFile } from '@/api/file';
import UA from '@/client/ua';
import ClientApp from '@/client/index';

export default defineComponent({
  name: 'TosUploader',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    VanUploader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isTakePhoto: {
      type: Boolean,
      default: false
    },
    isFront: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    bgType: {
      type: String,
      default: ''
    }
  },
  emits: ['afterUploaded', 'change'],
  setup(props, context) {
    const currentValueRef = ref([]);
    const uploaderRef = ref();
    const bgClassRef = computed(() => {
      const bg = props.bgType;
      return bg || 'default';
    });
    watch(
      () => props.value,
      val => {
        currentValueRef.value = val;
      },
      { immediate: true }
    );

    watch(
      () => currentValueRef.value,
      val => {
        if (currentValueRef?.value[0]?.fileName && !currentValueRef?.value[0]?.url) {
          beforeLoadCardFront(currentValueRef.value[0].fileName, () => {
            context.emit('change', val);
          });
        } else {
          context.emit('change', val);
        }
      }
    );

    const isAndroidClient = ref(UA.androidClient);

    async function afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中';
      try {
        const res = await uploadFile(file.file);
        const { data } = res;
        if (data) {
          const { url, fileName, thumbnail } = data;
          file.url = url;
          file.fileName = fileName;
          file.thumbnail = thumbnail;
        }
        file.status = 'done';
        context.emit('afterUploaded', file);
      } catch (error) {
        file.status = 'done';
        console.error(error);
      } finally {
        file.status = 'done';
      }
    }

    async function onTakePhoto() {
      try {
        const data = props.isTakePhoto
          ? await ClientApp.takePhoto({ isFront: props.isFront })
          : await ClientApp.openAlbum({ minNum: 1, maxNum: 1, isCamera: true, type: props.type });
        if (data) {
          const file = data[0];
          const fileList = [file];
          currentValueRef.value = fileList;
          context.emit('change', fileList);
          context.emit('afterUploaded', file);
        }
      } catch (error) {
        console.error(error);
      } finally {
        currentValueRef.value[0].status = 'done';
      }
    }

    async function beforeLoadCardFront(fileName, callback) {
      // 图片回填只有fileName时触发事件
      try {
        const res = await apiFileTemporaryUrl({ filename: fileName });
        const { data } = res;
        if (data) {
          // 去掉后面的签名，兼容不同的安卓机
          const index = data.indexOf('?Expires');
          const url = index > -1 ? data.slice(0, index) : data;
          currentValueRef.value[0].url = url;
        }
      } catch (error) {
        console.log(error);
      } finally {
        currentValueRef.value[0].status = 'done';
        callback();
      }
    }

    function onDelete() {
      uploaderRef.value.deleteFile(currentValueRef.value[0], 0);
      context.emit('afterDelete');
    }

    return {
      afterRead,
      isAndroidClient,
      onTakePhoto,
      currentValueRef,
      beforeLoadCardFront,
      uploaderRef,
      onDelete,
      bgClassRef
    };
  }
});
</script>

<style lang="less" scope>
.uploader-wrap {
  width: 100%;
  position: relative;
}

.click-mask {
  top: 0;
  left: 0;
  z-index: 9;
}

.tos-uploader-bg {
  background: #f6f8fa;
  border-radius: 0.04rem;
  border: 0.005rem dashed #0076ff;
  max-height: 100%;
}

.icon-delete {
  position: absolute;
  right: -0.08rem;
  top: -0.08rem;
}

.van-uploader {
  position: relative;
  width: 100%;
  height: 100%;
  .van-uploader__preview-delete {
    display: none;
    background-color: #e72832;
  }
  .van-uploader__mask {
    width: 100%;
    height: 100%;
  }
  .van-uploader__preview {
    width: 100%;
    margin: 0;
    .van-uploader__file {
      background: transparent;
    }
  }
  .uploader-button-wrap {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
    .uploader-button {
      width: 100%;
      height: 100%;
      .camera-button {
        width: 0.4rem;
        height: 0.4rem;
        .icon {
          font-size: 0.4rem;
        }
      }
    }
  }
}
</style>

<style lang="less">
.tos-default-bg {
  .shenfenzheng-zhu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/shenfenzhengrenxiangmian@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .shenfenzheng-fu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/shenfenzhengguohuiye@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .jiashizheng {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/jiashizhengzhufuye%403x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .xingshizheng-zhu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/xingshizhengqitaye@3x.jpg')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .daoluyunshuzheng-zhu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/daoluyunshuzhengzhufuye%403x.jpg')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .congyezigezheng-zhu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/congyezigezheng%403x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .congyezigezheng-fu {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/congyezigezheng%403x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .rencheheying {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/zhengjiantuli/rencheheying.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .default {
    .van-uploader {
      .van-uploader__wrapper {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/xingshizheng_wrong-2@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
