import { defineStore } from 'pinia';
import { getRealNameInfoApi, realNameAuthApi } from '@api/autonym';
import { USER_VERTIFIED_INFO_KEY } from '@/enums/cacheEnum';
import { getDataSessionCache, setDataSessionCache } from '@/core/cache/data';
import { RealNameAuthStatus } from '@/constants';
import { apiRealNameCarrier } from '@/api/carrier';
import { useUserStore } from '@/store/user';

export const useUserVerifiedStore = defineStore({
  id: 'user-verified',
  state: () => ({
    verifiedInfo: Object.assign(
      {
        step: 0,
        address: '',
        authStatus: RealNameAuthStatus.Unknow,
        id: null,
        idCard: '',
        idCardBackUrl: '',
        idCardEndDate: 0,
        idCardFrontUrl: '',
        idCardIssueOrganization: '',
        idCardStartDate: 0,
        name: '',
        rejectReason: '',
        selfImageUrl: '',
        userId: 0,
        gender: 1,
        nation: '汉'
      },
      getDataSessionCache(USER_VERTIFIED_INFO_KEY) || {}
    )
  }),
  getters: {
    getAuthStatus() {
      return this.verifiedInfo.authStatus;
    },
    getIsEdit() {
      return !!this.verifiedInfo.id;
    }
  },
  actions: {
    updateInfo(val) {
      this.verifiedInfo = Object.assign(this.verifiedInfo, val);
    },

    saveInfo() {
      // 缓存到session中
      setDataSessionCache(USER_VERTIFIED_INFO_KEY, this.verifiedInfo);
    },

    async submitVerifiedInfo() {
      const userStore = useUserStore();
      try {
        // TODO: 实名认证分为两个接口 一个包含创建车队长(车队长身份调用) 一个普通调用
        const res = userStore.isDriver
          ? await realNameAuthApi({
              ...this.verifiedInfo,
              idCard: this.verifiedInfo.idCard.toUpperCase()
            })
          : await apiRealNameCarrier({
              ...this.verifiedInfo,
              idCard: this.verifiedInfo.idCard.toUpperCase()
            });
        const { data } = res;
        if (data) {
          this.verifiedInfo.authStatus = RealNameAuthStatus.Certification;
          this.verifiedInfo.step = 2;
          userStore.getUserInfoAction();
        }
      } catch (err) {
        console.log(err);
      }
    },

    async queryUserVerifiedInfo() {
      const res = await getRealNameInfoApi();
      const { data } = res;
      if (data) {
        this.verifiedInfo = { ...data, step: 2 };
      } else {
        this.verifiedInfo.step = 0;
      }
      return data;
    }
  }
});
