import { http } from '../utils/http';
const path = process.env.VUE_APP_PATH + '/basic-ability';

export const apiUpload = async data => {
  return await http['post'](`${path}/file/uploadFile`, data);
};

export const apiGetImgUrl = async data => {
  return await http['post'](`${path}/file/getFileTemporaryUrl`, data, false);
};
// 获取文件的临时访问url
export const apiFileTemporaryUrl = async data => {
  return await http['post'](`${path}/file/getFileTemporaryUrl`, data, false);
};
// 获取多个文件的临时访问url
export const apiMultFileTemporaryUrl = async data => {
  return await http['post'](`${path}/file/getMultFileTemporaryUrl`, data, false);
};

export const getThumbnailFileTemporaryUrlListApi = async params => {
  return await http['post'](`${path}/file/getThumbnailFileTemporaryUrlList`, params, false);
};

export const apiGetLicensesByPlate = async params => {
  return await http['post'](`${path}/open/listLicensePlate`, params, false);
};

// 1.3.4 获取模式
let prefix = process.env.VUE_APP_PATH + '/driver-center';
export const getPlatformAppAuditModeApi = async params => {
  return await http['post'](`${prefix}/driver/mine/config/getPlatformAppAuditMode`, params, false);
};
