<template>
  <Page :hasBg="false" :loading="loading" style="background: #fff;">
    <!-- 导航栏 -->
    <NavBar title="实名认证">
      <template #right>
        <span class="text-12 text-secondary" @click="handleReAuth">重新认证</span>
      </template>
    </NavBar>

    <div class="mb-16 bg-white auth-detail pa-16">
      <div class="mb-16 d-flex">
        <Icon name="cph" svgClass="mr-8 mt-2" />
        <div class="font-14 fw-500">身份信息：</div>
      </div>
      <div class="mb-40">
        <div class="mb-16 text_muted">身份证照片：</div>
        <div class="justify-between d-flex">
          <tos-uploader :disabled="true" class="mr-12 id-card" v-model="idCardFrontList" :max-count="1"></tos-uploader>
          <tos-uploader :disabled="true" class="id-card" v-model="idCardBackList" :max-count="1"></tos-uploader>
        </div>
        <div class="mb-40">
          <div class="mb-16 text_muted">人像照片</div>
          <tos-uploader :disabled="true" class="self-image" v-model="selfImageList" :max-count="1"></tos-uploader>
        </div>
      </div>
      <div class="d-flex justify-space-between detail-form">
        <div class="text_muted">身份证姓名</div>
        <div class="font-16">{{ userDetailRef.name }}</div>
      </div>
      <div class="d-flex justify-space-between detail-form">
        <div class="text_muted">身份证号码</div>
        <div class="font-16">{{ userDetailRef.idCard }}</div>
      </div>
      <div class="d-flex justify-space-between detail-form">
        <div class="text_muted">身份证地址</div>
        <div class="ml-24 text-right font-16 lh-20">{{ userDetailRef.address }}</div>
      </div>
      <div class="d-flex justify-space-between detail-form">
        <div class="text_muted">签发机关</div>
        <div class="font-16">{{ userDetailRef.idCardIssueOrganization }}</div>
      </div>
      <div class="d-flex flex-column detail-form no-border">
        <div class="text_muted">有效期</div>
        <div class="pt-12 time-block font-18 d-flex justify-space-between">
          <div class="text_main fw-500">{{ formatToDateTime(userDetailRef.idCardStartDate, 'yyyy-MM-dd') }}</div>
          <strong class="lh-40">至</strong>
          <div class="text_main fw-500">{{ formatToDateTime(userDetailRef.idCardEndDate, 'yyyy-MM-dd') }}</div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api';
import { useUserStore } from '@/store/user';
import NavBar from '@/components/NavBar/index.vue';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import TosUploader from '@/components/uploader/uploader.vue';
import { useUserVerifiedStore } from '@/store/verified';
import { Dialog } from 'vant';

export default defineComponent({
  name: 'userDetail',
  components: {
    NavBar,
    TosUploader
  },
  setup(props, ctx) {
    const userVertifiedStore = useUserVerifiedStore();

    const userStore = useUserStore();
    const loading = ref(true);
    const userDetailRef = computed(() => userStore.realNameAuthVO);

    const idCardFrontList = ref([
      {
        name: 'idCardFront'
      }
    ]);
    const idCardBackList = ref([
      {
        name: 'idCardBack'
      }
    ]);
    const selfImageList = ref([
      {
        name: 'selfImage'
      }
    ]);

    onMounted(() => {
      userVertifiedStore.queryUserVerifiedInfo();
      [idCardFrontList, idCardBackList, selfImageList].forEach(item => {
        if (userDetailRef.value[`${item.value[0].name}Url`]) {
          item.value = [
            {
              fileName: userDetailRef.value[`${item.value[0].name}Url`],
              status: 'uploading',
              message: '加载中'
            }
          ];
        }
      });
      loading.value = false;
    });

    // 重新实名认证
    const { $router } = ctx.root;

    let authLoading = false;
    const handleReAuth = async () => {
      Dialog.confirm({
        message: '是否确定重新进行实名认证'
      })
        .then(() => {
          if (authLoading) return;
          authLoading = true;
          try {
            userVertifiedStore.updateInfo({
              step: 0,
              address: null,
              authStatus: null,
              id: null,
              idCard: null,
              idCardBackUrl: null,
              idCardEndDate: null,
              idCardFrontUrl: null,
              idCardIssueOrganization: null,
              idCardStartDate: null,
              name: null,
              rejectReason: null,
              selfImageUrl: null,
              userId: null,
              gender: null,
              nation: null
            });
            $router.push('/autonym?action=reAuth');
          } catch (error) {
            console.log(error);
          } finally {
            authLoading = false;
          }
        })
        .catch(err => console.log(err));
    };

    return {
      loading,
      formatToDateTime,
      userDetailRef,
      idCardFrontList,
      idCardBackList,
      selfImageList,
      handleReAuth
    };
  }
});
</script>
<style lang="less">
.self-image {
  height: 1.76rem;
}
.detail-form {
  line-height: 0.24rem;
  padding: 0.16rem 0;
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  &.no-border {
    border-bottom: none;
  }
  .text_muted {
    min-width: 0.7rem;
  }
  .time-block {
    div {
      width: 1.55rem;
      text-align: center;
      height: 0.4rem;
      border: 1px solid #bcbcbc;
      border: 1px solid rgba(188, 188, 188, 0.5);
      border-radius: 5px;
      line-height: 0.4rem;
    }
  }
}
.id-card {
  .van-uploader__preview {
    width: 1.63rem;
    height: 1.22rem;
  }
}
</style>
